import { graphql, navigate, PageProps } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { constructCartDataByProduct } from "../../../helpers/cart";
import { objectifyFormData } from "../../../helpers/form";
import httpRequest from "../../../helpers/http";
import { getExtension, getImageLink } from "../../../helpers/images";
import { globalReducer } from "../../../helpers/reducers";
import CartStorage from "../../../helpers/storage";
import { validateOrderLineItem } from "../../../helpers/validator/cart";
import { AttributesPropsTypes, ImageType } from "../../../types/global";
import Layout from "../../layout";
import SEO from "../../seo";
import { LineItemType, ProductPageDataType } from "./types";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from "swiper";
import Alert from "../../alert";
import ProductsRows from "../../products-rows";
import Modal from "../../modal";
import { scrollIntoView } from "../../../helpers/ui";

import "glightbox/dist/css/glightbox.min.css";
import 'swiper/css';
import "swiper/css/bundle";
import "./style.css";
import "../store-page/ql-text-editor-style.css";

const initState = {
  loading: false,
  data: null,
  errors: null,
  message: null,
};
export default function Product({ data: { product, storeDetails, allWcProducts: { edges: products } } }: PageProps<ProductPageDataType>) {
  const [state, dispatch] = useReducer(globalReducer, initState);
  const quantityRef = useRef<HTMLInputElement>(null!);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const alertMessage = state.message || state.errors?.global;
  const alertVariant = state.message ? "success" : "danger";
  const isOutOfStock = (Number(product?.stock_quantity) <= 0 || product.stock_status === "outofstock") && product.manage_stock;
  const productDates = {
    first_publication_date: product.date_created.date,
    last_publication_date: product.date_modified.date,
  };
  const onSubmit = (mode: "buy-now" | "add-to-cart") => async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isOutOfStock) return;
    dispatch({
      type: "LOADING",
      payload: true
    });

    const form = mode === "add-to-cart"
      ? event.currentTarget
      : document.querySelector(".js-buy-now-form") as HTMLFormElement;
    const formData = new FormData(form);
    const orderData = objectifyFormData(formData);
    const lineItem = constructCartDataByProduct(orderData);
    const errors = validateOrderLineItem(lineItem, product?.attributes as AttributesPropsTypes[]);

    if (errors !== false) {
      return dispatch({
        type: "ERROR",
        payload: errors
      });
    }

    const response = await httpRequest({
      url: `${storeDetails.product_stock_url}/${orderData.product_id}/availability?quantity=${orderData.quantity}`,
      requestConfig: {
        method: "GET",
      }
    });

    if (response?.code !== "success") {
      dispatch({
        type: "ERROR",
        payload: response.errors
      });
    } else {
      const cart = new CartStorage();
      const productDetails = {
        id: String(product.wordpress_id),
        slug: product.slug,
        name: product.name,
        regular_price: product.regular_price,
        thumbnail: product.thumbnail,
      }
      await Promise.all([
        cart.addItem(lineItem as LineItemType),
        cart.pushItemDetails(productDetails)
      ]);
      mode === "add-to-cart" && dispatch({
        type: "SUCCESS",
        payload: "Ajouter au panier avec succes"
      });
    }
    // scroll to the alert element
    if (mode === "add-to-cart") {
      scrollIntoView(document.querySelector(".alert"));
    }
    // navigate to checkout if a customer presson "buy now" button
    if (mode === "buy-now") {
      await navigate("/cart/checkout");
    }
  }
  const onEditQuantity = (increase = true) => () => {
    if (quantityRef.current !== null) {
      const { value = "1" } = quantityRef.current;
      let quantity = parseInt(value, 10);
      quantity = increase === true
        ? quantity + 1
        : quantity === 1
          ? 1
          : quantity - 1;
      quantityRef.current.value = `${quantity}`;
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      import("glightbox/dist/js/glightbox.min.js")
        .then(({ default: GLightbox }) => {
          GLightbox({
            touchNavigation: true,
          });
        });
    }
    return () => {

    }
  }, []);

  return (
    <Layout className="page-holder bg-light">
      <div id={`product-${product.id}`}>
        <SEO
          banner={String(product.images?.[0].src)}
          pathname={`/product/${product.slug}/`}
          description={product?.short_description || product?.name || ""}
          title={product.name}
          node={productDates}
          product={product}
          article={false}
        />

        <section className="py-5">
          <div className="container">
            {/* <!-- MODAL ---> */}
            <Modal />
            {/* PRODUCT FORM */}
            <div className="row mb-5">
              <div className="col-lg-6">
                {/* <!-- PRODUCT SLIDER--> */}
                <div className="row m-sm-0">
                  <div className="col-sm-2 p-sm-0 order-2 order-sm-1 mt-2 mt-sm-0 px-xl-2">
                    <Swiper
                      direction="horizontal"
                      className={"product-slider-thumbs h-100"}
                      slidesPerView={5}
                      spaceBetween={10}
                      modules={[Thumbs]}
                      watchSlidesProgress
                      // @ts-expect-error
                      onSwiper={setThumbsSwiper}
                      breakpoints={{
                        576: {
                          direction: "vertical",
                          slidesPerView: 1,
                          spaceBetween: 10,
                          // autoHeight: true,
                        },
                      }}
                    >
                      {
                        product?.images?.map((productImage: ImageType | null) => {
                          // avoid error if image is null
                          // @ts-expect-error
                          if (productImage === null || !!productImage === false) {
                            return null;
                          }

                          const { height, width, id, src } = productImage;
                          const images = {
                            sources: [
                              {
                                media: "",
                                type: "image/webp",
                                srcSet: getImageLink(src),
                              },
                              {
                                media: "",
                                type: `image/${getExtension(src)}`,
                                srcSet: src,
                              }
                            ]
                          };
                          const imageWidth = 80;
                          const imageHeight = height * imageWidth / width;
                          const image: IGatsbyImageData = {
                            height: imageHeight,
                            width: imageWidth,
                            backgroundColor: "#12206755",
                            layout: "fixed",
                            images
                          };

                          return (
                            <SwiperSlide
                              key={`product-swiper-slide-image-${id}`}
                              className="h-auto swiper-thumb-item mb-3"
                            >
                              <GatsbyImage
                                image={image}
                                alt={product.name}
                                data-type={"_image_"}
                                imgClassName={"w-100"}
                                className={"mw-100"}
                              />
                            </SwiperSlide>
                          );
                        })
                      }
                    </Swiper>
                  </div>
                  <div className="col-sm-10 order-1 order-sm-2">
                    <Swiper
                      className="product-slider h-100"
                      slidesPerView={1}
                      spaceBetween={0}
                      modules={[Thumbs]}
                      thumbs={{ swiper: thumbsSwiper }}
                    >
                      {
                        product?.images?.map((productImage: ImageType | null, index) => {
                          // avoid error if image is null
                          // @ts-expect-error
                          if (productImage === null || !!productImage === false) {
                            return null;
                          }

                          const { height, width, id, src } = productImage;
                          const srcSet = getImageLink(src);
                          const images = {
                            sources: [
                              {
                                media: "",
                                type: "image/webp",
                                srcSet,
                              },
                              {
                                media: "",
                                type: `image/${getExtension(src)}`,
                                srcSet: src,
                              }
                            ]
                          };
                          const image: IGatsbyImageData = {
                            height,
                            width,
                            backgroundColor: "#12206755",
                            layout: "fullWidth",
                            images
                          };

                          return (
                            <SwiperSlide
                              key={`product-swiper-slide-main-image-${id}`}
                              className={"h-auto"}>
                              <a
                                className="glightbox product-view d-block h-100"
                                href={srcSet}
                                data-gallery="gallery2"
                                data-glightbox={`Product image item ${index}`}
                              >
                                <GatsbyImage
                                  image={image}
                                  alt={product.name}
                                  data-type={"_image_"}
                                  imgClassName={"img-fluid"}
                                  className={"w-100 h-100"}
                                />
                              </a>
                            </SwiperSlide>
                          );
                        })
                      }
                    </Swiper>
                  </div>
                </div>
              </div>
              {/* <!-- PRODUCT DETAILS--> */}
              <form
                onSubmit={onSubmit("add-to-cart")}
                className="col-lg-6 js-buy-now-form"
              >
                <input name="product_id" value={product.wordpress_id} type="hidden" />
                <h1 className={"mt-0 mt-sm-3 mt-lg-0"}>{product.name}</h1>
                <p className="text-muted lead">{product.regular_price}DZD</p>
                <Alert variant={alertVariant}>
                  {alertMessage}
                </Alert>
                <div
                  className={"text-sm mb-4 text-truncated overflow-hidden"}
                  dangerouslySetInnerHTML={{ __html: product?.short_description || product?.description || "" }}
                />
                {
                  // @ts-ignore
                  product?.attributes?.length > 0 && (

                    <div className="row align-items-stretch mb-4">
                      <h4>Options</h4>
                      {
                        product.attributes?.map((attribute: any, index: number) => {
                          if (attribute.visible === true) {
                            return (
                              <div
                                key={`product-attribute-${attribute?.id}`}
                                // @ts-ignore
                                className={(index + 1) < product?.attributes?.length ? "mb-3" : ""}
                              >
                                <h6 className="mb-2">
                                  <strong className="text-capitalize">
                                    {attribute?.name}:
                                  </strong>
                                </h6>
                                <div className="d-flex flex-wrap gap-3">
                                  {
                                    attribute.options.map((option: any) => (
                                      <label key={`attribute-value-${option}`}>
                                        <input
                                          key={option}
                                          type={"radio"}
                                          name={`options[${attribute?.name}]`}
                                          value={option}
                                          className={"custom__radio-input d-none"}
                                        />
                                        <span className={"custom__radio-text py-2 px-3"}>
                                          {option}
                                        </span>
                                      </label>
                                    ))
                                  }
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })
                      }
                      {/* <span style={{ color: "#9a1111" }}>{state.errors?.options}</span> */}
                      {
                        !!state.errors?.options && (
                          <div className="invalid-feedback d-block mt-2">
                            {state.errors?.options}
                          </div>
                        )
                      }
                    </div>
                  )
                }
                <div className="row align-items-stretch mb-4">
                  <div className="col-sm-5 pr-sm-0">
                    <div className="border d-flex align-items-center justify-content-between py-1 px-3 bg-white border-white"><span className="small text-uppercase text-gray mr-4 no-select">Quantité</span>
                      <div className="quantity">
                        <button
                          className="dec-btn p-0"
                          onClick={onEditQuantity(false)}
                          type={"button"}
                        >
                          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg">
                            <path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" />
                          </svg>
                        </button>
                        <input
                          className="form-control border-0 shadow-0 p-0"
                          type="text"
                          value={"1"}
                          name="quantity"
                          ref={quantityRef}
                        />
                        <button
                          className="inc-btn p-0"
                          onClick={onEditQuantity()}
                          type={"button"}
                        >
                          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7 pl-sm-0 d-flex flex-column flex-lg-row gap-3">
                    <button
                      className="btn btn-dark btn-sm btn-block h-100 d-flex w-100 align-items-center justify-content-center"
                      type="button"
                      disabled={isOutOfStock || state.loading}
                      onClick={onSubmit("buy-now") as any}
                    >
                      {
                        isOutOfStock
                          ? "Rupture de stock"
                          : "Acheter maintenant"
                      }
                    </button>
                    <button
                      className="btn btn-outline-dark btn-sm btn-block h-100 d-flex w-100 align-items-center justify-content-center"
                      type="submit"
                      disabled={isOutOfStock || state.loading}
                    >
                      {
                        isOutOfStock
                          ? "Rupture de stock"
                          : "Ajouter au panier"
                      }
                    </button>
                  </div>
                  {
                    !!state.errors?.quantity && (
                      <div className="invalid-feedback d-block mt-2">
                        {state.errors?.quantity}
                      </div>
                    )
                  }
                </div>
                <ul className="list-unstyled small d-inline-block">
                  {
                    !!product.sku && (
                      <li className="px-3 py-2 mb-1 bg-white">
                        <strong className="text-uppercase">SKU:</strong>{` `}
                        <span className="ms-2 text-muted">{product.sku}</span>
                      </li>
                    )
                  }
                  {
                    (!!product?.width || !!product?.height) && (
                      <li className="px-3 py-2 mb-1 bg-white text-muted">
                        <strong className="text-uppercase text-dark">Dimensions(cm):</strong>{` `}
                        <span>{product?.width || ""}</span>{` `}
                        &times;{` `}
                        <span>{product?.height || ""}</span>
                      </li>
                    )
                  }
                  {
                    !!product?.length && (
                      <li className="px-3 py-2 mb-1 bg-white text-muted">
                        <strong className="text-uppercase text-dark">Longueur(cm):</strong>{` `}
                        <span className="reset-anchor ms-2">{product?.length || ""}</span>
                      </li>
                    )
                  }
                  {
                    !!product?.weight && (
                      <li className="px-3 py-2 mb-1 bg-white text-muted">
                        <strong className="text-uppercase text-dark">Poids(kg):</strong>
                        <span className="reset-anchor ms-2">{product?.weight || ""}</span>
                      </li>
                    )
                  }
                </ul>
              </form>
            </div>
            {/* <!-- DETAILS TABS--> */}
            <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
              <li className="nav-item"><a className="nav-link text-uppercase active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">Description</a></li>
            </ul>
            <div className="tab-content mb-5" id="myTabContent">
              <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                <div className="p-4 p-lg-5 bg-white">
                  <h6 className="text-uppercase">Description du produit</h6>
                  <div
                    className="text-muted text-sm mb-0"
                    dangerouslySetInnerHTML={{ __html: String(product.description || "") }}
                  />
                </div>
              </div>
            </div>
            {/* <!-- RELATED PRODUCTS--> */}
            <h2 className="h5 text-uppercase mb-4">Recommander</h2>
            <div className="row">
              {/* <!-- PRODUCT--> */}
              <ProductsRows
                products={products}
                columnClassName={"col-lg-3 col-sm-6"}
              />
            </div>
          </div>
        </section>
      </div >
    </Layout >
  );
}

export const pageQuery = graphql`
  query ($id: String!, $productId: Int!, $categories: [Int]) {
    allWcProducts(
      filter: {
        category_ids: {
          in: $categories
        }
        wordpress_id: {
          ne: $productId
        }
      }
      limit: 4
    ) {
      edges {
        node {
          name
          slug
          id
          regular_price
          price
          wordpress_id
          description
          images {
            id
            src
            height
            width
          }
        }
      }
    }
    product: wcProducts (id: {eq: $id }) {
      id
      wordpress_id
      name
      slug
      regular_price
      price
      description
      short_description
      sku
      length
      height
      width
      weight
      images {
        id
        src
        height
        width
      }
      thumbnail {
        id
        src
        height
        width
      }
      attributes {
        id
        name
        options
        visible
      }
      date_created {
        date
        timezone
      }
      date_modified{
        date
        timezone_type
      }
      stock_quantity
      stock_status
      manage_stock
    }
    storeDetails: zzStoreWebsiteDetails {
      product_stock_url
    }
  }
`;